import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { tokenVerification } from "../../../services/Customer";
import { errorToast } from "../../Toasts/Toasts";

const VerificationPage = () => {
    const { token } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const getTokenVerificationStatus = async () => {
          try {

            const tokenVerified = await tokenVerification(token);
            const verificationPhoneDetails = JSON.stringify({phoneNumber : tokenVerified?.data?.request_sender_phone_number, phoneCode : tokenVerified?.data?.request_sender_phone_code});
            localStorage.setItem("verificationPhoneDetails", verificationPhoneDetails);

            if (tokenVerified?.data?.customerRegistered) {
              navigate(`/customer/send-topup/${tokenVerified?.data?.request_sender_phone_code}${tokenVerified?.data?.request_sender_phone_number}`, { state: { amount: (parseFloat(tokenVerified?.data?.amount)).toFixed(2), rechargeRequestId:(tokenVerified?.data?.recharge_request_id_token), isRechRequest: true, carrier_id:(tokenVerified?.data?.carrier_id)}});
            } else {
              navigate("/customer/register");
            }
          } catch (error) {
            errorToast(error?.response?.data?.message);
          }
        };
      
        getTokenVerificationStatus();
        
        // eslint-disable-next-line 
      }, [token]);

    return(
        <>
        </>
    );
};

export default VerificationPage;