import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { errorToast, successToast } from "../../Toasts/Toasts";
import { updateContactDetails, updateCustomerProfile } from "../../../services/customerService";
import { getCustomerProfileApi } from "../../../redux/features/customerProfileSlice";
import { useDispatch } from "react-redux";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
const ProfileUpdated = ({ showEditProfileModal, setShowEditProfileModal, selectedContact, masterData, handleUpdateContact, numberDisabled, emailDisabled }) => {

  const [phoneNumber, setPhoneNumber] = useState(selectedContact?.phone_number);
  const [phoneCode, setPhoneCode] = useState(selectedContact?.phone_code);
  const [phoneCodeError, setPhoneCodeError] = useState(false);
  const [carrierValidationMessage, setCarrierValidationMessage] = useState("");

  const {
    handleSubmit,
    control,
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({ defaultValues: { mobile_number: selectedContact?.phone_code && selectedContact?.phone_number ? `${selectedContact?.phone_code} ${selectedContact?.phone_number}` : "", email: selectedContact?.email ?? "" } });

  const ALLOWED_FILE_TYPES = ["image/jpeg", "image/png", "image/jpg"];
  const maxSize = 1 * 1024 * 1024; // 1 MB;

  const dispatch = useDispatch();

  const handleClose = () => setShowEditProfileModal(false);

  const [avatar, setAvatar] = useState(selectedContact?.image);
  const carrierOptions = () => {
    return masterData?.carriers?.map((data) => ({
      label: data?.name,
      value: data?.id,
    }));
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    // Check if a file is selected
    if (file) {
      // Check if the file type is allowed
      if (ALLOWED_FILE_TYPES.includes(file.type)) {
      // Check if the file size is within the limit
      if (file.size <= maxSize) {
        const reader = new FileReader();
        reader.onloadend = () => {
        const base64String = reader.result;
          setAvatar(base64String); // Store the base64 string
        };
        reader.readAsDataURL(file);
      } else {
        // Show error message for oversized file
        errorToast("Maximum file size allowed is 1 MB.");
      }
      } else {
        // Show error message for invalid file type
        errorToast("Only JPEG, PNG, and JPG images are allowed.");
      }
    }
  };

  const onSubmit = async (data) => {
    const button = document.getElementById("saveButton");
    button.disabled = true;

    let ContactInfo = {
      name: data?.customer_name,
      carrier_id: data?.carrier?.value || 0, // Corrected carrier_id assignment
      email: data?.email,
      image: avatar,
      phone_code: phoneCode ?? phoneCode,
      phone_number: phoneNumber ?? phoneNumber,
    };

    try {
      if (selectedContact?.contact_record_type === "customer") {
        const response = await updateCustomerProfile(ContactInfo);
        if (response?.success) {
          successToast(response?.message);
          handleUpdateContact(response);
          handleClose();
          button.disabled = true;
          dispatch(getCustomerProfileApi());
        } else {
          errorToast(response?.message || "Unknown error occurred.");
        }
      } else {
        const response = await updateContactDetails(selectedContact?.id, ContactInfo);
        if (response?.success) {
          successToast(response?.message);
          handleUpdateContact(response);
          handleClose();
          button.disabled = true;
        } else {
          errorToast(response?.message || "Unknown error occurred.");
        }
      }
    } catch (error) {
      errorToast(error?.response?.data?.message || "An error occurred while updating contact details.");
      button.disabled = false;
    }
  };

  const handleChange = (value) => {
    const stringValue = String(value);
    const parsedPhoneNumber = parsePhoneNumber(stringValue);
    if (parsedPhoneNumber) {
      setPhoneCode(`+${parsedPhoneNumber?.countryCallingCode}`);
      setPhoneNumber(parsedPhoneNumber?.nationalNumber);
      setValue("mobile_number", stringValue);
      setPhoneCodeError(false);
    } else {
      setPhoneCode("");
      setPhoneNumber("");
    }
    if (value) {
      setValue("mobile_number", value, { shouldValidate: true });
      setPhoneCodeError(false);
    }
    if (value === undefined) {
      setValue("mobile_number", setPhoneCode, { shouldValidate: true });
      setPhoneCodeError(true);
    }
  };

  useEffect(() => {
    const updateCarrier = (phoneNumber, phoneCode) => {
  
      const prefixToCarrierName = {
        "+93750": "Wasel",
        "+9378": "Etisalat",
        "+9373": "Etisalat",
        "+9379": "Roshan",
        "+9372": "Roshan",
        "+9374": "Salaam",
        "+9377": "MTN",
        "+9376": "MTN",
        "+9371": "AWCC",
        "+9370": "AWCC",
        "+9375": "Cdma",
      };
  
      const fullPhoneNumber = `${phoneCode}${phoneNumber}`;
      const sortedPrefixes = Object.keys(prefixToCarrierName).sort(
        (a, b) => b.length - a.length
      );
  
      const carrierName = sortedPrefixes.find((prefix) =>
        fullPhoneNumber.startsWith(prefix)
      );
  
      if (carrierName) {
        const carrier = masterData?.carriers?.find(
          (carrier) => carrier?.name === prefixToCarrierName[carrierName]
        );
        if (carrier) {
          setValue("carrier", { label: carrier?.name, value: carrier?.id });
          setCarrierValidationMessage("");
          return;
        }
      }
  
      const otherCarrier = masterData?.carriers?.find(
        (carrier) => carrier?.id === 0 
      );
      if (otherCarrier) {
        setValue("carrier", { label: otherCarrier?.name, value: otherCarrier?.id });
        setCarrierValidationMessage("");
      } else {
        setValue("carrier", null); 
      }
    };
  
    if (phoneNumber && phoneCode && showEditProfileModal && masterData) {
      updateCarrier(phoneNumber, phoneCode);
    } else if (!phoneNumber) {
      const otherCarrier = masterData?.carriers?.find(
        (carrier) => carrier?.id === 0 && carrier?.name === "Other"
      );
      if (otherCarrier) {
        setValue("carrier", { label: otherCarrier?.name, value: otherCarrier?.id });
        setCarrierValidationMessage("");
      } else {
        setValue("carrier", null); 
      }
    }
  }, [phoneNumber, phoneCode, showEditProfileModal, masterData, setValue]);
  
  

  const handleSkip = async () => {
    const isValid = await trigger(["mobile_number", "carrier", "email"]);

    if (!isValid) {
      return;
    }
    const ContactInfo = {
      phone_code: phoneCode ?? phoneCode,
      phone_number: phoneNumber ?? phoneNumber,
      carrier_id: control._formValues.carrier?.value || 0,
      name: control._formValues.customer_name,
      email: control._formValues.email,
      image: avatar,
    };

    try {
      const response = await updateCustomerProfile(ContactInfo);
      if (response?.success) {
        successToast(response?.message);
        handleUpdateContact(response);
        handleClose();
        dispatch(getCustomerProfileApi());
      } else {
        errorToast(response?.message || "Unknown error occurred.");
      }
    } catch (error) {
      errorToast(error?.response?.data?.message || "An error occurred while updating contact details.");
    }
  };

  return (
    <Modal show={showEditProfileModal} size="sm" centered>
      <div className="modal-simple">
        <div className="modal-content p-2 p-md-3">
          <Modal.Body>
            <div className="text-left mb-4">
              <h3 className="mb-2">Edit Details</h3>
            </div>
            <div className="add-number">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="profile-change gap-4">
                      <div
                        className="custom-img-container rounded"
                        style={{
                          backgroundImage: `url(${avatar})`,
                        }}
                      />
                      <div className="button-wrapper mt-3">
                        <label htmlFor="upload" className="btn btn-outline-primary rounded-pill btn-sm me-2 mb-3 waves-effect waves-light" tabIndex={0}>
                          <span className="d-none d-sm-block">Upload new photo</span>
                          <i className="ti ti-upload d-block d-sm-none" />
                          <input type="file" id="upload" className="account-file-input" hidden accept="image/png, image/jpeg, image/jpg" onChange={handleImageChange} />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div>
                      <label htmlFor="" className="form-label">
                        Name
                      </label>
                      <input type="text" placeholder="" className="form-control" name="customer_name" minLength={1} maxLength={256} {...register("customer_name", { required: "required" })} />
                      <small className="text-danger">{errors?.customer_name && errors.customer_name.message}</small>
                    </div>
                  </div>
                  <div className="col-lg-12 mt-2">
                    <label className="form-label">Mobile Number</label>
                    <Controller
                      name="mobile_number"
                      control={control}
                      rules={{
                        validate: (value) => {
                          if (!value || typeof value !== "string") {
                            return "Phone number is required";
                          }
                          const phoneNumber = value.replace(/[^0-9]/g, "");
                          return (phoneNumber.length >= 7 && phoneNumber.length <= 15) || "Phone number must be between 7 and 15 digits";
                        },
                      }}
                      render={({ field }) => (
                        <>
                          {numberDisabled ? (
                            <input className="form-control" type="text" value={field.value} disabled={true} />
                          ) : (
                            <PhoneInput
                              className="form-control"
                              international
                              countryCallingCodeEditable={false}
                              defaultCountry="AF"
                              value={field.value}
                              onChange={(value) => {
                                handleChange(value);
                              }}
                            />
                          )}
                          {/* {phoneCodeError && <small className="text-danger">required</small>} */}
                          {errors?.mobile_number && <small className="text-danger">{errors.mobile_number.message}</small>}
                        </>
                      )}
                    />
                  </div>
                  <div className="col-lg-12 mt-2">
                    <div>
                      <label htmlFor="" className="form-label">
                        Email
                      </label>
                      <input
                        type="text"
                        className={`form-control  ${errors.email ? "is-invalid" : ""}`}
                        id="email"
                        {...register("email", {
                          required: "required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "Invalid email address",
                          },
                        })}
                        placeholder="Enter your email"
                        disabled={emailDisabled}
                      />
                      {errors.email && <small className="text-danger">{errors.email.message}</small>}{" "}
                    </div>
                  </div>
                  {/* <div className="col-lg-12 mt-2">
                    <label className="form-label">Carrier</label>
                    <Controller
                      name="carrier"
                      control={control}
                      rules={{ required: "required" }}
                      render={({ field }) => (
                        <>
                          <Select {...field} options={carrierOptions()} isSearchable placeholder="Select Carrier..." />
                          {errors.carrier && <small className="text-danger">{errors.carrier.message}</small>}
                          <small className="text-danger">{carrierValidationMessage}</small>
                        </>
                      )}
                    />
                  </div> */}

                  <div className="col-12 text-center mt-4">
                    <button type="submit" className="btn btn-primary rounded-pill me-sm-3 me-xl-1" id="saveButton" disabled={!!carrierValidationMessage}>
                      Update
                    </button>

                    <button type="button" className="btn btn-secondary rounded-pill ms-2" onClick={handleSkip} disabled={!!carrierValidationMessage}>
                      Skip
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Modal.Body>
        </div>
      </div>
    </Modal>
  );
};

export default ProfileUpdated;
