import React from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { getSessionId } from "../../services/Customer";
import { loadStripe } from "@stripe/stripe-js";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { errorToast } from "../Toasts/Toasts";
import { resetPaymentState } from "../../redux/features/paymentGatewaySlice";
import { useDispatch } from "react-redux";

const SelectPaymentMethod = ({ showBox, setShowBox, setSelectedValue, selectedValue, amount, carrierId }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleChange = (e) => {
    setSelectedValue(e.target.value);
  };

  const handleSubmit = async () => {
    const stripe = await loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);
    const response = await getSessionId({ amount: amount ?? 0, carrierId: carrierId });
    const result = stripe.redirectToCheckout({
      sessionId: response?.data?.session_id,
    });
    setShowBox(false);
    if (result.error) {
      console.log(result.error);
    }
  };
  const handleClose = () => {
    setShowBox(false);
    setSelectedValue(); // Reset to the default value when closing the modal'
    localStorage.removeItem("__paypal_storage__");
    localStorage.removeItem("rechargeDetails");
    dispatch(resetPaymentState());
  }

  return (
    <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_SANDBOX_CLIENT_ID }}>
      <Modal show={showBox} size="sm" centered>
        <div className="modal-simple">
          <div className="modal-content p-3 p-md-3">
            <Modal.Body>
              <div className="text-left mb-4">
                <h5 className="mb-2">Select Your Payment Method</h5>
              </div>
              <div className="row">
                <label className="d-flex justify-content-between mb-3 cursor-pointer">
                  <img src="../../assets/img/paypal.svg" alt="PayPal" />
                  <input type="radio" name="payment" value="1" checked={selectedValue === "1"} onChange={handleChange} />
                </label>
                <hr style={{ color: "var(--toastify-color-info)" }} />
                <label className="d-flex justify-content-between cursor-pointer">
                  <img src="../../assets/img/stripe.svg" alt="Stripe" /> 
                  <img src="../../assets/img/ma_symbol.svg" alt="MasterCard" style={{ width: '56px', margin: '0 6px' }} />
                  <img src="../../assets/img/american-express-svgrepo-com.svg" alt="American Express" style={{ height: '52px', width: '56px', margin: '0 10px' }} />
                  <img src="../../assets/img/visa-svg-com.svg" alt="visa" style={{ height: '52px', width: '56px', margin: '0 10px' }} />

                  <input type="radio" name="payment" value="0" checked={selectedValue === "0"} onChange={handleChange} />
                </label>
              </div>
              
              <div className="row">
                <div className="col-12 text-center mt-4">
                  {selectedValue === "1" && (
                    <PayPalButtons
                      style={{ layout: "vertical" }}
                      fundingSource="paypal"
                      createOrder={(data, actions) => {
                        return actions.order.create({
                          purchase_units: [
                            {
                              amount: {
                                value: amount, // Specify the amount here
                              },
                            },
                          ],
                        });
                      }}
                      onApprove={(data, actions) => {
                        return actions.order.capture().then((details) => {
                          let transactionId = details.purchase_units[0].payments.captures[0].id;
                          navigate('/customer/profile',{ state: { session_id: transactionId, carrierId: carrierId } });
                          dispatch(resetPaymentState());
                          setTimeout(() => {
                            setShowBox(false);
                          }, 1000);
                        });
                      }}
                      onError={(err) => {
                        const jsonMatch = err.message.match(/\{.*\}/s);

                        if (jsonMatch) {
                            const jsonString = jsonMatch[0];
                            const jsonData = JSON.parse(jsonString);  // Parse the JSON string into an object
                            errorToast(`[Error:${jsonData.name}][${jsonData.details[0].issue}] - ${jsonData.details[0].description}`);
                        } else {
                          errorToast('Something went wrong. Try again later or contact administrator');
                        }
                        navigate('/customer/profile');
                        dispatch(resetPaymentState());
                      }}
                    />
                  )}
                  {selectedValue === "0" && (
                    <>
                      <button type="submit" id="saveButton" className="btn btn-primary rounded-pill me-sm-3 me-1 waves-effect waves-light" onClick={handleSubmit}>
                        Confirm
                      </button>
                      <button type="reset" className="btn btn-label-secondary rounded-pill btn-reset waves-effect" onClick={handleClose}>
                        Cancel
                      </button>
                    </>
                  )}
                </div>
              </div>
              {selectedValue !== "0" &&<div className="row">
                <div className="col-12 text-center mt-4 mb-0">
                  <button type="reset" className="btn btn-label-secondary rounded-pill btn-reset waves-effect" onClick={handleClose}>Cancel</button>
                </div>
              </div>}
            </Modal.Body>
          </div>
        </div>
      </Modal>
    </PayPalScriptProvider>
  );
};

export default SelectPaymentMethod;
